<template>
  <div ref="sectionRef" class="bg-noiseGradientGreen">
    <div class="grid-default container gap-y-10 py-28 text-white lg:py-36">
      <div class="col-span-12 lg:order-2 lg:col-start-7 lg:col-end-13">
        <Heading
          v-if="data.title"
          :content="data.title"
          :level="2"
          class="mb-4 font-bold lg:mb-6"
        />
        <div v-if="data.html" class="mb-8 text-base lg:mb-12 lg:text-xl" v-html="data.html" />
        <div class="grid-default gap-y-4">
          <NuxtLink
            v-for="(item, index) in data.countries"
            :key="index"
            class="group col-span-6 flex h-10 w-fit items-center justify-center gap-4 rounded-[80px] py-2 pl-2 pr-4 transition-colors duration-300 hover:bg-neutral-800 lg:h-auto lg:p-4"
            :to="{ path: $t('footer.locationsLink'), query: { name: item.name } }"
            noPrefetch
          >
            <Icon
              name="pin"
              color="white"
              :size="isLG ? '' : 'sm'"
              :class="isLG ? 'min-w-[1.5rem]' : 'min-w-[1.25rem]'"
            />
            <span class="line-clamp-1 break-all text-base lg:text-2xl">
              {{ item.name }}
            </span>
            <div
              v-if="isDesktop"
              class="hidden items-center justify-center rounded-[64px] px-3 py-[0.375rem] transition-colors duration-300 group-hover:bg-teal-500 lg:flex lg:px-4 lg:py-2"
            >
              <Icon name="arrow-right" color="white" :size="isLG ? 'lg' : 'sm'" />
            </div>
          </NuxtLink>
        </div>
      </div>
      <div class="col-span-12 lg:order-1 lg:col-span-5">
        <div v-if="Array.isArray(data.mediaBlocks) && data.mediaBlocks.length" class="mx-auto">
          <LazyLottie v-if="isSectionVisible" :data="data.mediaBlocks[0]" />
        </div>
        <Image
          v-else-if="data.media"
          loading="lazy"
          class="object-cover"
          :sizes="data.media.responsiveImage?.sizes"
          :src="data.media.responsiveImage ? data.media.responsiveImage.src : data.media.url"
          :srcset="data.media.responsiveImage ? data.media?.responsiveImage.webpSrcSet : undefined"
          :width="data.media.responsiveImage ? data.media.responsiveImage.width : data.media.width"
          :height="
            data.media.responsiveImage ? data.media.responsiveImage.height : data.media.height
          "
          :alt="data.media.alt ? data.media.alt : ''"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionWorldwideRecord } from '~/types/generated'
import { theme } from '#tailwind-config'

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)
const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greater('lg')
const { isDesktop } = useDevice()

defineProps({
  data: {
    type: Object as PropType<SectionWorldwideRecord>,
    default: () => {}
  }
})

const { stop } = useIntersectionObserver(sectionRef, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    isSectionVisible.value = true
  }
})

onUnmounted(() => {
  stop()
})
</script>
